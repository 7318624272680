import { useState } from 'react'
import { cn } from '~/utils'

export default function Image({
  className,
  src,
  alt,
  lazy = true,
}: {
  className?: string
  src: string
  alt?: string
  lazy?: boolean
}) {
  const [isLoaded, setIsLoaded] = useState(lazy ? false : true)
  return (
    <img
      className={cn(
        className,
        'opacity-0 transition-opacity duration-500',
        isLoaded && 'opacity-100'
      )}
      src={src}
      alt={alt}
      onLoad={() => {
        setIsLoaded(true)
      }}
      loading={lazy ? 'lazy' : 'eager'}
      draggable={false}
    />
  )
}
